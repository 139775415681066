import React, { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";
import { Text } from "../Text";
import { divide, reverse, sortBy, uniq } from "lodash";
import { useWindowSize } from "usehooks-ts";
import { rgba } from "polished";

interface ICreditAnimations {
    marqueeItemWidth: number;
}

const SAMPLE_WIDTH = 1511;

const CREDITS = reverse([
    {
        role: "Illustration",
        name: "Bhavya Arora"
    },
    {
        role: "Art direction",
        name: "Kasturi Singh"
    },
    {
        role: "Cheerleader",
        name: "Tejas Bhatt"
    },
    {
        role: "Code",
        name: "Meet Bhatt"
    },
    {
        role: "Illustration",
        name: "Bhavya Arora"
    },
    {
        role: "Art direction",
        name: "Kasturi Singh"
    },
    {
        role: "Cheerleader",
        name: "Tejas Bhatt"
    },
    {
        role: "Code",
        name: "Meet Bhatt"
    }
]);

export const CreditAnimations = ({ marqueeItemWidth }: ICreditAnimations) => {
    const mainRef = useRef(null);
    const marqueeTl = useRef<GSAPTimeline>(null);
    const repeatTl = useRef<GSAPTimeline>(null);
    const MAIN_DURATION = 20;

    const { width: windowWidth, height: windowHeight } = useWindowSize();

    let mainTl = null;
    useLayoutEffect(() => {
        const SHIFT = 0.25;
        const ctx = gsap.context(() => {
            const allItems =
                gsap.utils.toArray<HTMLElement>(".credits-text-role");
            const duration = allItems.length - 1;
            const itemWidth = () => allItems[0].offsetWidth;

            const allNameItems =
                gsap.utils.toArray<HTMLElement>(".credits-text-name");
            const nameItemWidth = () => allNameItems[0].offsetWidth;

            const TEMP_VAR = (allItems.length - 1) * SHIFT;
            const DERIVED_DURATION = duration / (1 + TEMP_VAR);

            // gsap.set(allItems, {
            //     clearProps: "all"
            // });
            // gsap.set(allNameItems, {
            //     clearProps: "all"
            // });

            const getSingleTlForName = () => {
                const tl = gsap.timeline({});
                allNameItems.forEach((item, index) => {
                    tl.to(
                        item,
                        {
                            x: () => -nameItemWidth(),
                            ease: "none",
                            // immediateRender: true,
                            duration: duration / allItems.length
                        },
                        `<+=${index > 0 ? SHIFT * 100 : 0}%`
                    );
                });

                // console.log(DERIVED_DURATION, tl.totalDuration(), duration);
                return tl;
            };

            const getSingleTlForRole = () => {
                const tl = gsap.timeline({});
                allItems.forEach((item, index) => {
                    tl.to(
                        item,
                        {
                            x: () => itemWidth(),
                            // scale: 0.5,
                            // color: "black",
                            ease: "none",
                            immediateRender: true,
                            duration: duration / allItems.length
                        },
                        `<+=${index > 0 ? SHIFT * 100 : 0}%`
                    );
                });

                return tl;
            };

            repeatTl.current = gsap
                .timeline({
                    paused: true,
                    defaults: {
                        overwrite: true,
                    }
                })
                .add(getSingleTlForRole(), 0)
                .add(getSingleTlForName(), 0)
                // .add(getSingleTlForRole(), `+=${duration}`)
                // .add(getSingleTlForName(), `+=${duration}`);

            const START_TIMES = uniq(
                sortBy(
                    repeatTl.current
                        .getChildren(true, true, false)
                        .map((t) => t.startTime())
                )
            );
            // console.log(
            //     duration,
            //     DERIVED_DURATION * SHIFT,
            //     DERIVED_DURATION * SHIFT + (allItems.length / 2) * SHIFT
            // );

            const MARKER1 = START_TIMES[2];
            const MARKER2 = START_TIMES[2 + allItems.length / 2];
            marqueeTl.current = gsap
                .timeline({
                    repeat: -1,
                    paused: true,
                    // delay: 0.5,
                    defaults: {
                        ease: "none"
                    }
                })
                .add(
                    repeatTl.current.tweenFromTo(MARKER2, MARKER1, {
                        ease: "none",
                        // delay: 2,
                        // ease: gentleEase,
                        // overwrite: true,
                        duration: MAIN_DURATION
                    }),
                    "="
                );

            marqueeTl.current.play();
        }, mainRef);

        return () => {
            ctx.revert();
        };
    }, [marqueeItemWidth]);

    return (
        <div ref={mainRef}>
            <div
                css={{
                    position: "relative",
                    display: "block"
                    // position: "absolute",
                    // top: 0,
                    // left: 0,
                    // width: "100%",
                    // overflow: "hidden",
                }}
            >
                <div
                    css={{
                        perspective: "400px",
                        perspectiveOrigin: "center left"
                    }}
                >
                    <div
                        css={{
                            transformStyle: "preserve-3d",
                            transformOrigin: "center left",
                            // "rotateY(-20deg) rotateX(-10deg) translateY(180%) rotateZ(31deg) scale(3) skewX(0deg)"
                            transform:
                                "rotateY(15deg) rotateX(-5deg) translateY(260px) rotateZ(-29deg) scale(3) skewX(0deg)"
                        }}
                    >
                        <div
                            className="credits-text-role-container-container"
                            css={{
                                marginTop: "600px",
                            }}
                        >
                            <div
                                className="credits-text-role-container"
                                css={{
                                    position: "relative",
                                    // display: "inline-grid",
                                    width: `${marqueeItemWidth}px`,
                                    overflow: "hidden",
                                    // gridTemplateColumns: `repeat(${CREDITS.length}, ${marqueeItemWidth}px)`
                                }}
                            >
                                <div
                                    css={{
                                        zIndex: 2,
                                        position: "absolute",
                                        top: "0",
                                        left: 0,
                                        width: "100%",
                                        height: "100%",
                                        background:
                                            "linear-gradient(270deg, rgba(0,0,0,1) 62%, rgba(0,0,0,0) 80%)"
                                    }}
                                />
                                {reverse(CREDITS).map((item, index) => (
                                    <Text
                                        className="credits-text-role"
                                        css={{
                                            position:
                                                index === 0
                                                    ? "relative"
                                                    : "absolute",
                                            zIndex: 1,
                                            width: `${marqueeItemWidth}px`,
                                            left: 0,
                                            top: 0,

                                            transform: "translateX(-100%)",
                                            textAlign: "right",
                                            display: "inline-block",
                                            willChange: "transform",
                                            transformOrigin: "center"
                                            // transform: `rotateY(57deg) translate(100px,235px) rotateX(30deg) scale(1.5) rotateZ(-3.5deg) skewX(0deg)`
                                        }}
                                        variant="credits"
                                        color="white"
                                    >
                                        {item.role}
                                    </Text>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                css={{
                    display: "block",
                    // position: "absolute",
                    top: 0
                    // right: 0
                }}
            >
                <div
                    css={{
                        perspective: "400px",
                        perspectiveOrigin: "center right"
                    }}
                >
                    <div
                        css={{
                            transformStyle: "preserve-3d",
                            transformOrigin: "center right",
                            transform:
                                "rotateY(-20deg) rotateX(-5deg) translateY(240%) rotateZ(30deg) scale(3) skewX(0deg)"
                        }}
                    >
                        <div
                            className="credits-text-name-container-container"
                            css={{
                                display: "block",
                                // marginTop: 200,
                                left: 0
                            }}
                        >
                            <div
                                className="credits-text-name-container"
                                css={{
                                    position: "relative",
                                    width: `${marqueeItemWidth}px`,
                                    overflow: "hidden",
                                }}
                            >
                                <div
                                    css={{
                                        zIndex: 2,
                                        position: "absolute",
                                        top: "0",
                                        left: 0,
                                        width: "100%",
                                        height: "100%",
                                        background:
                                            "linear-gradient(90deg, rgba(0,0,0,1) 55%, transparent 70%)"
                                        // background:
                                        //     "linear-gradient(90deg, rgba(0,0,0,1) 20%, rgba(0,0,0,0) 25%)"
                                    }}
                                />
                                {CREDITS.map((item, index) => (
                                    <Text
                                        className="credits-text-name"
                                        css={{
                                            position:
                                                index === 0
                                                    ? "relative"
                                                    : "absolute",
                                            width: `${marqueeItemWidth}px`,
                                            left: 0,
                                            top: 0,
                                            right: 0,
                                            bottom:0,
                                            transform: "translateX(100%)",
                                            textAlign: "left",
                                            display: "block",
                                            willChange: "transform"
                                            // transformOrigin: ,
                                            // transform: `rotateY(57deg) translate(100px,235px) rotateX(30deg) scale(1.5) rotateZ(-3.5deg) skewX(0deg)`
                                        }}
                                        variant="credits"
                                        color="white"
                                    >
                                        {item.name}
                                    </Text>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*<div*/}
            {/*    css={{*/}
            {/*        display: "block",*/}
            {/*        position: "absolute",*/}
            {/*        top: 0,*/}
            {/*        right: 0,*/}
            {/*        perspective: "1000px",*/}
            {/*        perspectiveOrigin: "center"*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <Text*/}
            {/*        className="credits-text-2"*/}
            {/*        css={{*/}
            {/*            display: "block",*/}
            {/*            transformStyle: "preserve-3d"*/}
            {/*            // transformOrigin: ,*/}
            {/*            // transform: `rotateY(-57deg) translateY(195px) translateX(80px) rotateX(30deg) scale(1.2) rotateZ(6deg) skewX(0deg)`*/}
            {/*        }}*/}
            {/*        variant="credits"*/}
            {/*        color="white"*/}
            {/*    >*/}
            {/*        Bhavya Arora*/}
            {/*    </Text>*/}
            {/*</div>*/}
        </div>
    );
};
